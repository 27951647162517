import React from 'react';
import styles from './OptionsList.module.scss';
import Option from './Option';


export default function OptionsList(props) {
    
    const availableOptions = props.options.filter(option => option.value.toLocaleLowerCase().indexOf(props.search.toLocaleLowerCase()) !== -1);
    const optionsMarkup = availableOptions.map(option => {
        return <Option key={option.id} name={option.name} value={option.value} search={props.search} onClick={(selected => props.onSelect(selected))} />;
    })
    
    return <section className={styles.optionsList}>
        {optionsMarkup}
    </section>;
}