import React from 'react';
import styles from './Questionnaire.module.scss';

import Translations from '../translations/Translations';

import { questionsList, todosList } from '../initialData';
import Question from './Question';
import QuestionDetails from './QuestionDetails';

import Button from '../form/Button';

export default class Questionnaire extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.selectQuestion = this.selectQuestion.bind(this);
        this.updateQuestionName = this.updateQuestionName.bind(this);
        this.updateQuestionType = this.updateQuestionType.bind(this);
        
        this.toggleOptionAddForm = this.toggleOptionAddForm.bind(this);
        
        this.updateOptionName = this.updateOptionName.bind(this);
        this.updateOptionTodo = this.updateOptionTodo.bind(this);
        this.updateOptionGoto = this.updateOptionGoto.bind(this);
        
        this.handleOptionSelection = this.handleOptionSelection.bind(this);
        this.handleOptionReorder = this.handleOptionReorder.bind(this);
        
        this.addOption = this.addOption.bind(this);
        this.addQuestion = this.addQuestion.bind(this);
        
        let questions = questionsList,
            todos = todosList;
        
        this.state = {
            questions: questions,
            todos: todos,
            
            selectedQuestion: null,
            selectedOption: null,
            selectedIndex: null,
            
            showAddOptionForm: false,
            newOptionName: '',
            newOptionTodo: '',
            newOptionGoto: ''
        }
        
        for (let i = 0; i < this.state.questions.length; i += 1) {
            let question = this.state.questions[i];
            
            if (question.type === 'Single Select' || question.type === 'Multi Select') {
                for (let j = 0; j < question.options.length; j += 1) {
                    let option = question.options[j];
                    
                    for (let k = 0; k < this.state.todos.length; k += 1) {
                        if (option.todo === this.state.todos[k].id) {
                            option.todo = Object.assign({}, this.state.todos[k]);
                            break;
                        }
                    }
                }
            }
        }
        
        
    }
    
    selectQuestion(question, index) {
        this.setState({
            selectedQuestion: Object.assign({}, question),
            selectedIndex: index
        });
    }
    
    updateQuestionName(name) {
        let newState = Object.assign({}, this.state);
        
        newState.selectedQuestion.question = name;
        
        for (let i = 0; i < newState.questions.length; i += 1) {
            let question = newState.questions[i];
            
            if (question.id === newState.selectedQuestion.id) {
                newState.questions[i] = Object.assign({}, newState.selectedQuestion);
            }
        }
        
        this.setState(newState);
    }
    
    updateQuestionType(type) {
        let newState = Object.assign({}, this.state);
        
        newState.selectedQuestion.type = type;
        
        if (type === 'Single Select' || type === 'Multi Select') {
            newState.selectedQuestion.options = [];
        } else {
            newState.selectedQuestion.options = null;
        }
        
        for (let i = 0; i < newState.questions.length; i += 1) {
            let question = newState.questions[i];
            
            if (question.id === newState.selectedQuestion.id) {
                newState.questions[i] = Object.assign({}, newState.selectedQuestion);
            }
        }
        
        this.setState(newState);
    }
    
    addQuestion() {
        let newState = Object.assign({}, this.state);
        
        let new_id = 1;

        if (newState.questions.length > 0) {
            new_id = newState.questions[newState.questions.length - 1].id + 1;
        }
        
        newState.questions.push({
            id: new_id,
            question: '',
            type: 'Text'
        });
        
        this.setState(newState);
    }
    
    toggleOptionAddForm() {
        let toggledState = !this.state.showAddOptionForm;
        
        this.setState({
            showAddOptionForm: toggledState
        });
    }
    
    updateOptionName(value) {
        this.setState({
            newOptionName: value
        });
    }
    
    updateOptionTodo(value) {
        
        if (isNaN(value)) {
            return;
        }
        
        this.setState({
            newOptionTodo: Number(value)
        });
    }
    
    updateOptionGoto(value) {
        
        if (isNaN(value)) {
            return;
        }
        
        this.setState({
            newOptionGoto: Number(value)
        });
    }
    
    addOption() {
        let newState = Object.assign({}, this.state);
        
        let new_id = 1;

        if (newState.selectedQuestion.options.length > 0) {
            new_id = newState.selectedQuestion.options[newState.selectedQuestion.options.length - 1].id + 1;
        }
        
        let newOption = {
            id: new_id,
            name: newState.newOptionName,
            todo: newState.newOptionTodo,
            goto: newState.newOptionGoto
        };
                    
        for (let i = 0; i < this.state.todos.length; i += 1) {
            if (newOption.todo === this.state.todos[i].id) {
                newOption.todo = Object.assign({}, this.state.todos[i]);
                break;
            }
        }
        
        newState.selectedQuestion.options.push(newOption);
        
        for (let i = 0; i < newState.questions.length; i += 1) {
            let question = newState.questions[i];
            
            if (question.id === newState.selectedQuestion.id) {
                newState.questions[i] = Object.assign({}, newState.selectedQuestion);
            }
        }
        
        newState.newOptionName = '';
        newState.newOptionType = '';
        newState.newOptionGoto = '';
        newState.showAddOptionForm = false;
        
        this.setState(newState);
    }
    
    reOrder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }
    
    handleOptionSelection(card_id) {
        let optionCard;
        
        for (let i = 0; i < this.state.selectedQuestion.options.length; i += 1) {
            let option = this.state.selectedQuestion.options[i];
            
            if (option.id === card_id) {
                optionCard = option;
                break;
            }
        }
        
        this.setState({
            selectedOption: optionCard
        });
    }
    
    handleOptionReorder(source_index, destination_index) {
        let newState = Object.assign({}, this.state);
        
        newState.selectedQuestion.options = this.reOrder(newState.selectedQuestion.options, source_index, destination_index);
        
        for (let i = 0; i < newState.questions.length; i += 1) {
            let question = newState.questions[i];
            
            if (question.id === newState.selectedQuestion.id) {
                newState.questions[i] = Object.assign({}, newState.selectedQuestion);
            }
        }
        
        this.setState(newState);
    }
    
    render() {
        const questions = this.state.questions.map((question, index) => {
            return <Question key={question.id} question={question} index={index} onSelect={this.selectQuestion} selected={this.state.selectedQuestion && question.id === this.state.selectedQuestion.id} />
        });
        
        return (
            <section className={styles.FocusSpace}>
                <section className={styles.questionsList}>
                    {questions}
                </section>
                <section className={styles.selectedQuestion}>
                    {this.state.selectedQuestion ? <QuestionDetails index={this.state.selectedIndex} question={this.state.selectedQuestion} onOptionNameUpdate={this.updateOptionName} onOptionTodoUpdate={this.updateOptionTodo} onOptionGotoUpdate={this.updateOptionGoto} onOptionAddFormToggle={this.toggleOptionAddForm} showOptionAddForm={this.state.showAddOptionForm} onAddOption={this.addOption} totalNumberOfQuestions={this.state.questions.length} onOptionSelection={this.handleOptionSelection} onQuestionNameUpdate={this.updateQuestionName} selectedOption={this.state.selectedOption} onOptionReorder={this.handleOptionReorder} onQuestionTypeUpdate={this.updateQuestionType} /> : <Button text="Add Question" onClick={this.addQuestion} />}
                </section>
                <Translations />
            </section>
        );
    }
}