import React from 'react';
import styles from './Sidebar.module.scss';
import { NavLink } from "react-router-dom";

import logo from './logo.svg';
import { ReactComponent as DashboardIcon } from './dashboard.svg';
import { ReactComponent as StructureIcon } from './structure.svg';
import { ReactComponent as MembersIcon } from './members.svg';
import { ReactComponent as TodoIcon } from './todo.svg';
import { ReactComponent as QuestionnaireIcon } from './questionnaire.svg';
import { ReactComponent as WorkflowIcon } from './workflow.svg';

export default function Sidebar() {
    return (
        <section className={styles.SideBar} id="navigation-side-menu">
            <picture className={styles.Logo}>
                <img src={logo} alt="oaas logo" />
            </picture>
            <nav>
                <NavLink to="/not-found" className={styles.NavigationLink} activeClassName={styles.active}>
                    <DashboardIcon />
                    <span> Dashboard </span>
                </NavLink>
                <NavLink to="/structure" className={styles.NavigationLink} activeClassName={styles.active}>
                    <StructureIcon />
                    <span> Structure </span>
                </NavLink>
                <div className={styles.NavHeader}> FIELD WORK </div>
                <NavLink to="/not-found" className={styles.NavigationLink} activeClassName={styles.active}>
                    <MembersIcon />
                    <span> Members </span>
                </NavLink>
                <NavLink to="/todos" className={styles.NavigationLink} activeClassName={styles.active}>
                    <TodoIcon />
                    <span> To-Do's </span>
                </NavLink>
                <NavLink to="/questionnaire" className={styles.NavigationLink} activeClassName={styles.active}>
                    <QuestionnaireIcon />
                    <span> Questionnaire </span>
                </NavLink>
                <NavLink to="/workflows" className={styles.NavigationLink} activeClassName={styles.active}>
                    <WorkflowIcon />
                    <span> Workflows </span>
                </NavLink>
                <div className={styles.NavHeader}> MORE </div>
            </nav>
        </section>
    );
}