import React from 'react';
import styles from './CardForm.module.scss';

export default function CardForm(props) {
    
    return (
        <section className={styles.addLevel}>
            {props.children}
            <button className={styles.submitFormButton} onClick={props.submitForm}>Add</button>
        </section>
    );
}