import React from 'react';
import styles from './UsersTable.module.scss';

import editIcon from './edit.svg';
import deleteIcon from './trash.svg';

export default function UsersTable(props) {
    let usersMarkup = props.usersList.map((user, index) => {
        return (
            <tr key={user.id}>
                <td className={styles.slNo}>{index}</td>
                <td className={styles.username}>{user.username}</td>
                <td className={styles.level}>{user.level}</td>
                <td className={styles.entity}>{user.entity}</td>
                <td className={styles.role}>{user.role}</td>
                <td className={styles.manager}>{user.manager ? 'Y' : 'N'}</td>
                <td className={styles.actions}>
                    <img src={editIcon} alt="Edit User"/>
                    <img src={deleteIcon} alt="Delete User"/>
                </td>
            </tr>
        );
    })
    return (
        <table className={styles.table} cellSpacing="0">
            <thead>
                <tr>
                    <th className={styles.slNo}>Sl. No</th>
                    <th className={styles.username}>User Name</th>
                    <th className={styles.level}>Level</th>
                    <th className={styles.entity}>Entity</th>
                    <th className={styles.role}>Role</th>
                    <th className={styles.manager}>Manager</th>
                    <th className={styles.actions}></th>
                </tr>
            </thead>
            <tbody>
                {usersMarkup}
            </tbody>
        </table>
    );
}