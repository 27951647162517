import React from 'react';
import styles from './CardsList.module.scss';

export default function CardsList(props) {

    return (
        <section className={styles.cardsList}>
            <header> {props.heading}  {props.addForm && <button onClick={props.onAddToggle}> + Add </button>} </header>
            {props.showAddForm && props.addForm}
            <ul className={styles.structureList}>
                {props.children}
            </ul>
        </section>
    );
        
}