import React from 'react';
import styles from './InputText.module.scss';
import OptionsList from './OptionsList';


export default class InputText extends React.Component {
    constructor(props) {
        super(props);
        
        this.changeInput = this.changeInput.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.input = React.createRef();
        
        this.state = {
            inputValue: this.props.default || '',
            isFocussed: false,
            options: this.props.options && this.props.options.map((option, index) => { 
                return {
                    id: index,
                    name: option.name ? option.name : option,
                    value: option.value ? option.value : option
                }
            }),
            finalValue: ''
        };
    }
    
    changeInput(e) {
        const inputValue = e.target.value;
        const finalValue = this.props.options && this.props.options.indexOf(inputValue) === -1 ? '' : inputValue;
        
        this.props.onChange && this.props.onChange(finalValue);
        
        this.setState({
            inputValue: e.target.value,
            finalValue: finalValue
        });
    }
    
    onFocus(e) {
        this.setState({
            isFocussed: true
        });
    }
    
    onBlur(e) {
        /******************************************************************************************
         * Delay the blur by a little bit to allow the onSelect function to run.                  *
         *                                                                                        *
         * The blur event runs before the click event is registered on the <Option> component, so *
         * the element is removed from the DOM before it even runs. Since that happens, the       *
         * onSelect function doesn't even get a chance to run. We need to delay the hiding of the *
         * options on blur to avoid that.                                                         *
         ******************************************************************************************/
        setTimeout(function () {
            this.setState((state, props) => ({
                // Check if the input is focussed (the input may have gained focus between the blur and the delay)
                isFocussed: document.activeElement === this.input.current
            }));
        }.bind(this), 100);
    }
    
    onSelect(selected) {
        this.setState({
            inputValue: selected,
            finalValue: selected
        });
        
        this.input.current.value = selected;
        
        this.props.onChange && this.props.onChange(selected);
    }
    
    render () {
        return (
            <div className={styles.inputHolder}>
                <input type="text" defaultValue={this.state.inputValue} onChange={this.changeInput} onFocus={this.onFocus} onBlur={this.onBlur} className={this.state.inputValue ? styles.active : ''} readOnly={this.props.disableSearch} ref={this.input} />
                {this.props.placeholder && <div className={styles.placeholder}>{this.props.placeholder}</div>}
                {this.props.icon && <img className={styles.icon} src={this.props.icon} alt=""/>}
                {this.props.options && this.state.isFocussed && <OptionsList search={this.props.disableSearch ? '' : this.state.inputValue} options={this.state.options} onSelect={this.onSelect} />}
            </div>
        );
    }
}