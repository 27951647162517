import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Sidebar from './sidebar/Sidebar';
import PageHeader from './page-header/PageHeader';
import Structure from './structure/Structure';
import Todos from './todos/Todos';
import Questionnaire from './questionnaire/Questionnaire';
import Workflows from './workflows/Workflows';
import OrganizationProfile from './organization-profile/OrganizationProfile';

class App extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            selectedCard: null
        };
    }
    
    render() {
        return (
            <BrowserRouter>
                <div>
                    <Sidebar />
                    <PageHeader />
                    <Switch>
                        <Route path="/structure" component={() => <Structure />} />
                        <Route path="/profile" component={() => <OrganizationProfile />} />
                        <Route path="/todos" component={() => <Todos />} />
                        <Route path="/questionnaire" component={() => <Questionnaire />} />
                        <Route path="/workflows" component={() => <Workflows />} />
                    </Switch>
                    
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
