import React from 'react';
import styles from './Card.module.scss';
import { Draggable } from 'react-beautiful-dnd';

import deleteIcon from './trash.svg';

export default function Card(props) {
    
    return (
        <Draggable key={props.card.id} draggableId={props.card.id} index={props.index}>
            {(provided, snapshot) => (
                <li 
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={props.active ? styles.active : styles.normal} 
                    onClick={() => props.onSelectCard()}>
                    <div className={styles.index}> {props.index + 1} </div>
                    <div className={styles.details}> 
                        <div className={styles.title}>{props.card.name}</div> 
                        {props.card.details && <div className={styles.subTitle}>{props.card.details}</div> }
                    </div>
                    <div className={styles.delete}>
                        <img src={deleteIcon} alt="Delete card"/>
                    </div>
                </li>
            )}
        </Draggable>
    );
}