import React from 'react';
import styles from './CardsList.module.scss';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DraggableCard from '../card/DraggableCard';

function onCardDragEnd(reOrderCallback, result) {
    const { destination, source } = result;

    if (!destination) {
        return;
    }

    if (destination.index === source.index) {
        return;
    }
    
    reOrderCallback(source.index, destination.index);
}

export default function CardsList(props) {
    
    const cards = props.cards.map((card, index) => <DraggableCard key={card.id} index={index} card={card} onSelectCard={() => {props.onSelectCard(card.id)}} active={props.selected && props.selected.id === card.id}></DraggableCard>)

    return (
        <DragDropContext onDragEnd={(result) => {onCardDragEnd(props.onReorderCards, result)}}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <section 
                       className={styles.cardsList}
                       ref={provided.innerRef}
                    >
                        <header> {props.heading}  {props.addForm && <button onClick={props.onAddToggle}> + Add </button>} </header>
                        {props.showAddForm && props.addForm}
                        <ul className={styles.structureList}>
                            {cards}
                        </ul>
                        {provided.placeholder}
                    </section>
                )}
            </Droppable>
        </DragDropContext>
    );
        
}