import React from 'react';
import styles from './Card.module.scss';
import Toggle from '../form/Toggle';

export default function Card(props) {
    
    return (
        <li className={props.active ? styles.active : styles.normal}>
            <div className={styles.details}> 
                <div className={styles.title}>{props.card.name}</div> 
            </div>
            <div className={styles.toggleHolder}><Toggle on={props.on} onToggle={props.onToggle} toggleId={props.card.id} /></div>
        </li>
    );
}