import React from 'react';
import styles from './OrganizationProfile.module.scss';
import ColorPicker from '../form/ColorPicker';

import companyAvatar from './company-avatar.png';

import signPostImage from './directions.svg';
import listImage from './list.svg';
import locationPinImage from './maps-and-flags.svg';
import employeesImage from './network.svg';
import planImage from './plan.svg';
import billBoardImage from './street-name.svg';
import telephoneImage from './telephone.svg';
import codeImage from './vocabulary.svg';

export default function OrganizationProfile() {
    return (
        <section className={styles.OrganisationProfile}>
            <h2> Organisation Profile  <button> Edit </button> </h2>

            <section className={styles.Card}>
                <label htmlFor="profile-image" className={styles.ProfileImageHolder}>
                    <img src={companyAvatar} alt="company logo" />
                    <input type="file" id="profile-image" />
                </label>
                <div className={styles.ProfileDataHolder}>
                    <div className={styles.ContentFragment}>
                        <label>
                            <picture>
                                <img src={billBoardImage} alt="street name icon" />
                            </picture>
                            Organisation Name
                        </label>
                        <div className={styles.InputValue}> An Organisation </div>
                    </div>

                    <div className={styles.ContentFragment}>
                        <label>
                            <picture>
                                <img src={codeImage} alt="vocabulary icon" />
                            </picture>
                            Organisation Code
                        </label>
                        <div className={styles.InputValue}> ORG002 </div>
                    </div>

                    <div className={styles.ContentFragment}>
                        <label>
                            <picture>
                                <img src={locationPinImage} alt="location icon" />
                            </picture>
                            Location
                        </label>
                        <div className={styles.InputValue}> 12.95632, 77.34322 </div>
                    </div>

                    <div className={styles.ContentFragment}>
                        <label>
                            <picture>
                                <img src={listImage} alt="category icon" />
                            </picture>
                            Category
                        </label>
                        <div className={styles.InputValue}> NGO </div>
                    </div>

                    <div className={styles.ContentFragment}>
                        <label>
                            <picture>
                                <img src={planImage} alt="category icon" />
                            </picture>
                            Current Plan
                        </label>
                        <div className={styles.InputValue}> Gold Plan User </div>
                    </div>

                    <button className={styles.UpgradeButton}> UPGRADE PLAN </button>
                </div>
            </section>

            <section className={styles.Card}>
                <div className={styles.Address}>
                    <label>
                        <picture>
                            <img src={signPostImage} alt="Address icon" />
                        </picture>
                        Address
                    </label>
                    <div className={styles.InputValue}>
                        No.25, Raghavendra Nilaya,
                        AECS Layout, Ashwathnagar,
                        Bangalore, Karnataka 560094, India.
                    </div>
                </div>

                <div className={styles.ContentFragment}>
                    <label>
                        <picture>
                            <img src={telephoneImage} alt="Phone number icon" />
                        </picture>
                        Phone Number
                    </label>
                    <div className={styles.InputValue}>
                        +91 88392 39832
                    </div>
                </div>

                <div className={styles.ContentFragment}>
                    <label>
                        <picture>
                            <img src={employeesImage} alt="Phone number icon" />
                        </picture>
                        Number of Employees
                    </label>
                    <div className={styles.InputValue}>
                        10
                    </div>
                </div>
            </section>

            <section className={styles.Card}>
                <ColorPicker />
            </section>

        </section>
    );
}