import React from 'react';
import styles from './Workflows.module.scss';

import Translations from '../translations/Translations';
import DraggableCardsList from '../cards-list/DraggableCardsList';
import CardForm from '../card/CardForm';
import Card from '../card/Card';

import InputText from '../form/InputText';
import { workflowsList } from '../initialData';

export default class Configuration extends React.Component {
    
    constructor(props) {
        super(props);
        this.onSelectWorkflow = this.onSelectWorkflow.bind(this);
        
        this.handleStatusSelection = this.handleStatusSelection.bind(this);
        this.handleCategorySelection = this.handleCategorySelection.bind(this);
        this.handlePrioritySelection = this.handlePrioritySelection.bind(this);
        
        this.handleStatusReorder = this.handleStatusReorder.bind(this);
        this.handleCategoryReorder = this.handleCategoryReorder.bind(this);
        this.handlePriorityReorder = this.handlePriorityReorder.bind(this);
        
        this.addStatus = this.addStatus.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.addPriority = this.addPriority.bind(this);
        
        this.updateStatusName = this.updateStatusName.bind(this);
        this.updateStatusDue = this.updateStatusDue.bind(this);
        this.updateCategoryName = this.updateCategoryName.bind(this);
        this.updatePriorityName = this.updatePriorityName.bind(this);
        
        this.toggleStatusAddForm = this.toggleStatusAddForm.bind(this);
        this.toggleCategoryAddForm = this.toggleCategoryAddForm.bind(this);
        this.togglePriorityAddForm = this.togglePriorityAddForm.bind(this);
        
        this.state = {
            selectedStatus: null,
            selectedCategory: null,
            selectedPriority: null,
            
            newStatusName: '',
            newStatusDue: '',
            newCategoryName: '',
            newPriorityName: '',
            
            showAddStatusForm: false,
            showAddCategoryForm: false,
            showAddPriorityForm: false,
            
            selectedWorkflow: null
        };
    }
    
    onSelectWorkflow(workflow) {
        const selectedWorkflow = Object.assign({}, workflow);
        this.setState({
            selectedWorkflow: selectedWorkflow
        });
    }
    
    reOrder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }
    
    handleStatusSelection(card_id) {
        let statusCard;
        
        for (let i = 0; i < this.state.selectedWorkflow.statuses.length; i += 1) {
            let status = this.state.selectedWorkflow.statuses[i];
            
            if (status.id === card_id) {
                statusCard = status;
                break;
            }
        }
        
        this.setState({
            selectedStatus: statusCard,
            selectedCategory: null,
            selectedPriority: null
        });
    }
    
    handleStatusReorder(source_index, destination_index) {
        let newState = JSON.parse(JSON.stringify(this.state));
        
        newState.selectedWorkflow.statuses = this.reOrder(newState.selectedWorkflow.statuses, source_index, destination_index);
        
        
        this.setState(newState);
    }
    
    toggleStatusAddForm() {
        let toggledState = !this.state.showAddStatusForm;
        
        this.setState({
            showAddStatusForm: toggledState
        });
    }
    
    updateStatusName(value) {
        this.setState({
            newStatusName: value
        });
    }
    
    updateStatusDue(value) {
        
        if (!isNaN(value)) {
            this.setState({
                newStatusDue: Number(value)
            });
        }
    }
    
    addStatus() {
        let newState = Object.assign({}, this.state);
        
        let new_id = 1;

        if (newState.selectedWorkflow.statuses.length > 0) {
            new_id = newState.selectedWorkflow.statuses[newState.selectedWorkflow.statuses.length - 1].id + 1;
        }
        
        newState.selectedWorkflow.statuses.push({
            id: new_id,
            name: newState.newStatusName,
            due_in_days: newState.newStatusDue
        });
        
        newState.newStatusName = '';
        newState.newStatusDue = '';
        newState.showAddStatusForm = false;
        
        this.setState(newState);
    }
    
    handleCategorySelection(card_id) {
        let categoryCard;
        
        for (let i = 0; i < this.state.selectedWorkflow.categories.length; i += 1) {
            let category = this.state.selectedWorkflow.categories[i];
            
            if (category.id === card_id) {
                categoryCard = category;
                break;
            }
        }
        
        this.setState({
            selectedStatus: null,
            selectedCategory: categoryCard,
            selectedPriority: null,
        });
    }
    
    handleCategoryReorder(source_index, destination_index) {
        let newState = JSON.parse(JSON.stringify(this.state));
        
        newState.selectedWorkflow.categories = this.reOrder(newState.selectedWorkflow.categories, source_index, destination_index);
        
        this.setState(newState);
    }
    
    toggleCategoryAddForm() {
        let toggledState = !this.state.showAddCategoryForm;
        
        this.setState({
            showAddCategoryForm: toggledState
        });
    }
    
    updateCategoryName(value) {
        this.setState({
            newCategoryName: value
        });
    }
    
    addCategory() {
        let newState = Object.assign({}, this.state);
        
        let new_id = 1;

        if (newState.selectedWorkflow.categories.length > 0) {
            new_id = newState.selectedWorkflow.categories[newState.selectedWorkflow.categories.length - 1].id + 1;
        }
        
        newState.selectedWorkflow.categories.push({
            id: new_id,
            name: newState.newCategoryName
        });
        
        newState.newCategoryName = '';
        newState.showAddCategoryForm = false;
        
        this.setState(newState);
    }
    
    handlePrioritySelection(card_id) {
        let priorityCard;
        
        for (let i = 0; i < this.state.selectedWorkflow.priorities.length; i += 1) {
            let priority = this.state.selectedWorkflow.priorities[i];
            
            if (priority.id === card_id) {
                priorityCard = priority;
                break;
            }
        }
        
        this.setState({
            selectedStatus: null,
            selectedCategory: null,
            selectedPriority: priorityCard,
        });
    }
    
    handlePriorityReorder(source_index, destination_index) {
        let newState = JSON.parse(JSON.stringify(this.state));
        
        newState.selectedWorkflow.priorities = this.reOrder(newState.selectedWorkflow.priorities, source_index, destination_index);
        
        this.setState(newState);
    }
    
    togglePriorityAddForm() {
        let toggledState = !this.state.showAddPriorityForm;
        
        this.setState({
            showAddPriorityForm: toggledState
        });
    }
    
    updatePriorityName(value) {
        this.setState({
            newPriorityName: value
        });
    }
    
    addPriority() {
        let newState = Object.assign({}, this.state);
        
        let new_id = 1;

        if (newState.selectedWorkflow.priorities.length > 0) {
            new_id = newState.selectedWorkflow.priorities[newState.selectedWorkflow.priorities.length - 1].id + 1;
        }
        
        newState.selectedWorkflow.priorities.push({
            id: new_id,
            name: newState.newPriorityName
        });
        
        newState.newPriorityName = '';
        newState.showAddPriorityForm = false;
        
        this.setState(newState);
    }
    
    render() {
        
        const workflows = workflowsList.map((workflow, index) => {
            return <Card key={workflow.id} index={index} card={workflow} onSelectCard={() => {this.onSelectWorkflow(workflow)}} active={this.state.selectedWorkflow && this.state.selectedWorkflow.id === workflow.id}></Card>;
        });
        
        let statuses = [], categories = [], priorities = [];
        
        if (this.state.selectedWorkflow) {
            statuses = this.state.selectedWorkflow.statuses.map(status => {
                status.details = status.due_in_days !== null ? 'Due in ' + status.due_in_days + ' days' : 'No Due';
                return status;
            });
            categories = this.state.selectedWorkflow.categories;
            priorities = this.state.selectedWorkflow.priorities;
        }
        
        const addStatusForm = <CardForm submitForm={this.addStatus}>
            <InputText placeholder="Name" onChange={this.updateStatusName} />
            <InputText placeholder="Due In" onChange={this.updateStatusDue} />
        </CardForm>;
        
        const addCategoryForm = <CardForm submitForm={this.addCategory}>
            <InputText placeholder="Name" onChange={this.updateCategoryName} />
        </CardForm>;
        
        const addPriorityForm = <CardForm submitForm={this.addPriority}>
            <InputText placeholder="Name" onChange={this.updatePriorityName} />
        </CardForm>;
        
        return (
            <div>
                <section className={styles.allWorkflows}>
                    <h2>Workflows</h2>
                    <ul className={styles.workflowsHolder}>{workflows}</ul>
                </section>
                {this.state.selectedWorkflow && 
                <div className={styles.cardsTree}>
                    <DraggableCardsList 
                        heading="Statuses"
                        cards={statuses}
                        selected={this.state.selectedStatus} 
                        onSelectCard={this.handleStatusSelection} 
                        onReorderCards={this.handleStatusReorder}
                        addForm={addStatusForm}
                        showAddForm={this.state.showAddStatusForm}
                        onAddToggle={this.toggleStatusAddForm}
                    />

                    <DraggableCardsList 
                        heading="Categories" 
                        cards={categories}
                        selected={this.state.selectedCategory} 
                        onSelectCard={this.handleCategorySelection} 
                        onReorderCards={this.handleCategoryReorder}
                        addForm={addCategoryForm}
                        showAddForm={this.state.showAddCategoryForm}
                        onAddToggle={this.toggleCategoryAddForm}
                    />

                    <DraggableCardsList 
                        heading="Priorities" 
                        cards={priorities}
                        selected={this.state.selectedPriority} 
                        onSelectCard={this.handlePrioritySelection} 
                        onReorderCards={this.handlePriorityReorder}
                        addForm={addPriorityForm}
                        showAddForm={this.state.showAddPriorityForm}
                        onAddToggle={this.togglePriorityAddForm}
                    />
                </div>}
                <Translations />
            </div>
        );
    }
}