import React from 'react';
import styles from './Translations.module.scss';

export default function Translations() {
    return (
        <section className={styles.LanguageTranslations}>
            <header> Languages </header>
            <div className={styles.TranslationsHolder}>
                <div className={styles.InputHolder}>
                    <label> English </label>
                    <input type="text" />
                </div>

                <div className={styles.InputHolder}>
                    <label> ಕನ್ನಡ </label>
                    <input type="text" />
                </div>

                <div className={styles.InputHolder}>
                    <label> தமிழ் </label>
                    <input type="text" />
                </div>

                <div className={styles.InputHolder}>
                    <label> हिंदी </label>
                    <input type="text" />
                </div>

                <div className={styles.InputHolder}>
                    <label> తెలుగు </label>
                    <input type="text" />
                </div>

            </div>
        </section>
    );
}