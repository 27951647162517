import React from 'react';
import styles from './Levels.module.scss';
import DraggableCardsList from '../cards-list/DraggableCardsList';
import CardForm from '../card/CardForm';

import InputText from '../form/InputText';

export default class Levels extends React.Component {
    constructor(props) {
        super(props);
        
        this.handleTopLevelSelection = this.handleTopLevelSelection.bind(this);
        this.handleLevelSelection = this.handleLevelSelection.bind(this);
        this.handleRoleSelection = this.handleRoleSelection.bind(this);
        
        this.handleTopLevelReorder = this.handleTopLevelReorder.bind(this);
        this.handleLevelReorder = this.handleLevelReorder.bind(this);
        this.handleRoleReorder = this.handleRoleReorder.bind(this);
        
        this.addTopLevel = this.addTopLevel.bind(this);
        this.addLevel = this.addLevel.bind(this);
        this.addRole = this.addRole(this);
        
        this.updateTopLevelName = this.updateTopLevelName.bind(this);
        this.updateLevelName = this.updateLevelName.bind(this);
        this.updateRoleName = this.updateRoleName.bind(this);
        
        this.toggleTopLevelAddForm = this.toggleTopLevelAddForm.bind(this);
        this.toggleLevelAddForm = this.toggleLevelAddForm.bind(this);
        this.toggleRoleAddForm = this.toggleRoleAddForm.bind(this);
        
        this.state = {
            selectedTopLevel: null,
            selectedLevel: null,
            selectedRole: null,
            
            newTopLevelName: '',
            newLevelName: '',
            newRoleName: '',
            
            showAddTopLevelForm: false,
            showAddLevelForm: false,
            showAddRoleForm: false,
            
            levelsData: this.props.levelsData
        };
    }
    
    reOrder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }
    
    handleTopLevelSelection(card_id) {
        let topLevelCard;
        
        for (let i = 0; i < this.state.levelsData.children.length; i += 1) {
            let topLevel = this.state.levelsData.children[i];
            
            if (topLevel.id === card_id) {
                topLevelCard = topLevel;
                break;
            }
        }
        
        this.setState({
            selectedTopLevel: topLevelCard,
            selectedLevel: null,
            selectedRole: null
        });
    }
    
    handleTopLevelReorder(source_index, destination_index) {
        let newState = Object.assign({}, this.state);
        
        newState.levelsData.children = this.reOrder(newState.levelsData.children, source_index, destination_index);
        
        this.setState(newState);
    }
    
    toggleTopLevelAddForm() {
        let toggledState = !this.state.showAddTopLevelForm;
        
        this.setState({
            showAddTopLevelForm: toggledState
        });
    }
    
    updateTopLevelName(value) {
        this.setState({
            newTopLevelName: value
        });
    }
    
    addTopLevel() {
        let newState = Object.assign({}, this.state);
        
        let new_id = 1;

        if (newState.levelsData.children.length > 0) {
            new_id = newState.levelsData.children[newState.levelsData.children.length - 1].id + 1;
        }
        
        newState.levelsData.children.push({
            id: new_id,
            name: newState.newTopLevelName,
            children: []
        });
        
        newState.newTopLevelName = '';
        newState.showAddTopLevelForm = false;
        
        this.setState(newState);
    }
    
    handleLevelSelection(card_id) {
        let levelCard;
        
        for (let i = 0; i < this.state.selectedTopLevel.children.length; i += 1) {
            let level = this.state.selectedTopLevel.children[i];
            
            if (level.id === card_id) {
                levelCard = level;
                break;
            }
        }
        
        this.setState({
            selectedLevel: levelCard,
            selectedRole: null
        });
    }
    
    handleLevelReorder(source_index, destination_index) {
        let newState = Object.assign({}, this.state);
        
        for (let i = 0; i < newState.levelsData.children.length; i += 1) {
            if (newState.levelsData.children[i].id === newState.selectedTopLevel.id) {
                newState.levelsData.children[i].children = this.reOrder(newState.levelsData.children[i].children, source_index, destination_index);
                newState.selectedTopLevel = newState.levelsData.children[i];
            }
        }
        
        this.setState(newState);
    }
    
    toggleLevelAddForm() {
        let toggledState = !this.state.showAddLevelForm;
        
        this.setState({
            showAddLevelForm: toggledState
        });
    }
    
    updateLevelName(value) {
        this.setState({
            newLevelName: value
        });
    }
    
    addLevel() {
        let newState = Object.assign({}, this.state);
        
        if (!newState.selectedTopLevel) {
            return;
        }
        
        for (let i = 0; i < newState.levelsData.children.length; i += 1) {
            if (newState.levelsData.children[i].id === newState.selectedTopLevel.id) {
                let new_id = 1;

                if (newState.selectedTopLevel.children.length > 0) {
                    new_id = newState.selectedTopLevel.children[newState.selectedTopLevel.children.length - 1].id + 1;
                }
                
                newState.levelsData.children[i].children.push({
                    id: new_id,
                    name: newState.newLevelName,
                    children: []
                });
            }
        }
        
        newState.newLevelName = '';
        newState.showAddLevelForm = false;
        
        this.setState(newState);
    }
    
    handleRoleSelection(card_id) {
        let roleCard;
        
        for (let i = 0; i < this.state.selectedLevel.children.length; i += 1) {
            let role = this.state.selectedLevel.children[i];
            
            if (role.id === card_id) {
                roleCard = role;
                break;
            }
        }
        
        this.setState({
            selectedRole: roleCard
        });
    }
    
    handleRoleReorder(source_index, destination_index) {
        let newState = Object.assign({}, this.state);
        
        for (let i = 0; i < newState.levelsData.children.length; i += 1) {
            if (newState.levelsData.children[i].id === newState.selectedTopLevel.id) {
                for (let j = 0; j < newState.levelsData.children[i].children.length; j += 1) {
                    if (newState.levelsData.children[i].children[j].id === newState.selectedLevel.id) {
                        newState.levelsData.children[i].children[j].children = this.reOrder(newState.levelsData.children[i].children[j].children, source_index, destination_index);
                        newState.selectedTopLevel = newState.levelsData.children[i];
                        newState.selectedLevel = newState.levelsData.children[i].children[j];
                    }
                }
            }
        }
        
        this.setState(newState);
    }
    
    toggleRoleAddForm() {
        let toggledState = !this.state.showAddRoleForm;
        
        this.setState({
            showAddRoleForm: toggledState
        });
    }
    
    updateRoleName(value) {
        this.setState({
            newRoleName: value
        });
    }
    
    addRole() {
        let newState = Object.assign({}, this.state);
        
        if (!newState.selectedLevel) {
            return;
        }
        
        for (let i = 0; i < newState.levelsData.children.length; i += 1) {
            if (newState.levelsData.children[i].id === newState.selectedTopLevel.id) {
                for (let j = 0; j < newState.levelsData.children[i].children.length; j += 1) {
                    if (newState.levelsData.children[i].children[j].id === newState.selectedLevel.id) {
                        let new_id = 1;
                        
                        if (newState.selectedLevel.children.length > 0) {
                            new_id = newState.selectedLevel.children[newState.selectedLevel.children.length - 1].id + 1;
                        }
                        
                        newState.levelsData.children[i].children[j].children.push({
                            id: new_id,
                            name: newState.newRoleName
                        });
                    }
                }
            }
        }
        
        newState.newRoleName = '';
        newState.showAddRoleForm = false;
        
        this.setState(newState);
    }
    
    render() {
        const addTopLevelForm = <CardForm submitForm={this.addTopLevel}>
            <InputText placeholder="Name" onChange={this.updateTopLevelName} />
        </CardForm>;
        
        const addLevelForm = <CardForm submitForm={this.addLevel}>
            <InputText placeholder="Name" onChange={this.updateLevelName} />
        </CardForm>;
        
        const addRoleForm = <CardForm submitForm={this.addRole}>
            <InputText placeholder="Name" onChange={this.updateRoleName} />
        </CardForm>;
        
        return (
            <div className={styles.cardsTree}>
                <DraggableCardsList 
                    heading="Top Levels"
                    cards={this.state.levelsData.children}
                    selected={this.state.selectedTopLevel} 
                    onSelectCard={this.handleTopLevelSelection} 
                    onReorderCards={this.handleTopLevelReorder}
                    addForm={addTopLevelForm}
                    showAddForm={this.state.showAddTopLevelForm}
                    onAddToggle={this.toggleTopLevelAddForm}
                />
                
                <DraggableCardsList 
                    heading="Levels" 
                    cards={this.state.selectedTopLevel ? this.state.selectedTopLevel.children : []} 
                    selected={this.state.selectedLevel} 
                    onSelectCard={this.handleLevelSelection}  
                    onReorderCards={this.handleLevelReorder}
                    addForm={!!this.state.selectedTopLevel && addLevelForm}
                    showAddForm={this.state.showAddLevelForm}
                    onAddToggle={this.toggleLevelAddForm}
                />
                
                <DraggableCardsList 
                    heading="Roles" 
                    cards={this.state.selectedLevel ? this.state.selectedLevel.children : []} 
                    selected={this.state.selectedRole} 
                    onSelectCard={this.handleRoleSelection}   
                    onReorderCards={this.handleRoleReorder}
                    addForm={!!this.state.selectedLevel && addRoleForm}
                    showAddForm={this.state.showAddRoleForm}
                    onAddToggle={this.toggleRoleAddForm}
                />
            </div>
        );
    }
}