import React from 'react';
import styles from './Levels.module.scss';
import CardsList from '../cards-list/CardsList';
import ToggleCard from '../card/ToggleCard';

export default class Permissions extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.handleReadToggle = this.handleReadToggle.bind(this);
        this.handleWriteToggle = this.handleWriteToggle.bind(this);
        
        this.state = {
            permissions: this.props.permissions.map(permission => {
                return {
                    ...permission,
                    read: true,
                    write: false
                };
            })
        };
    }
    
    handleReadToggle(permission_id) {
        let newState = Object.assign([], this.state);
        
        for (let i = 0; i < newState.permissions.length; i += 1) {
            if (newState.permissions[i].id === permission_id) {
                newState.permissions[i].read = !newState.permissions[i].read;
                
                if (!newState.permissions[i].read) {
                    newState.permissions[i].write = false;
                }
            }
        }
        
        this.setState(newState);
    }
    
    handleWriteToggle(permission_id) {
        let newState = Object.assign([], this.state);
        
        for (let i = 0; i < newState.permissions.length; i += 1) {
            if (newState.permissions[i].id === permission_id) {
                newState.permissions[i].write = !newState.permissions[i].write;
                
                if (newState.permissions[i].write) {
                    newState.permissions[i].read = true;
                }
            }
        }
        
        this.setState(newState);
    }
    
    render () {
    
        const readPermissions = this.state.permissions.map((card, index) => {
            return <ToggleCard key={card.id} card={card} on={card.read} onToggle={this.handleReadToggle} />
        });
        
        const writePermissions = this.state.permissions.map((card, index) => {
            return <ToggleCard key={card.id} card={card} on={card.write} onToggle={this.handleWriteToggle} />
        });

        return (

            <div className={styles.cardsTree}>
                <CardsList heading="Read permissions">
                    {readPermissions}
                </CardsList>
                <CardsList heading="Write permissions">
                    {writePermissions}
                </CardsList>
            </div>
        );
        
    }
}