import React from 'react';
import styles from './QuestionDetails.module.scss';
import DraggableCardsList from '../cards-list/DraggableCardsList';
import CardForm from '../card/CardForm';

import InputText from '../form/InputText';
import { todosList } from '../initialData';

export default function QuestionDetails(props) {
    
    const todoOptions = todosList.map((todo) => {
        return {
            name: todo.name,
            value: String(todo.id)
        };
    })
    
    let questionOptions = [];
    
    for (let i = 1; i <= props.totalNumberOfQuestions; i += 1) {
        questionOptions.push(String(i));
    }
    
    const addOptionForm = <CardForm submitForm={props.onAddOption}>
        <InputText placeholder="Option" onChange={props.onOptionNameUpdate} />
        <InputText placeholder="Todo" onChange={props.onOptionTodoUpdate} options={todoOptions} />
        <InputText placeholder="Goto" onChange={props.onOptionGotoUpdate} options={questionOptions} disableSearch />
    </CardForm>;
    
    let options = [];
    
    if (props.question.options) {
        options = props.question.options.map((option, index) => {
            return {
                ...option,
                details: 'Todo: ' + (option.todo ? option.todo.name : 'None') + ' | Q' + option.goto
            };
        });
    }
    
    return (
        <section className={styles.questionDetails}>
            <section className={styles.questionIndex}>Question {props.index + 1}</section>
            <InputText placeholder="Question" default={props.question.question} onChange={props.onQuestionNameUpdate} />
            <InputText placeholder="Type" default={props.question.type} onChange={props.onQuestionTypeUpdate} options={["Single Select", "Multi Select", "Number", "Text"]} disableSearch />
            
            {props.question.options &&
            <DraggableCardsList 
                heading="Options"
                cards={options}
                selected={props.selectedOption}
                onSelectCard={props.onOptionSelection}
                onReorderCards={props.onOptionReorder}
                addForm={addOptionForm}
                showAddForm={props.showOptionAddForm}
                onAddToggle={props.onOptionAddFormToggle}
            />
            }
        </section>
    );
}