import React from 'react';
import styles from './OptionView.module.scss';

export default function OptionView(props) {
    return (
        <section className={styles.optionView}>
            <div className={styles.optionTextHolder}>
                <section className={styles.optionText}>{props.index + 1}. {props.option.name}</section>
                <section className={styles.nextQuestion}>Q{props.option.goto}</section>
            </div>
            {props.option.todo && <section className={styles.todoText}>Todo: {props.option.todo.name}</section>}
        </section>
    );
}