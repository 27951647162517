import React from 'react';
import styles from './ColorPicker.module.scss';

export default class ColorPicker extends React.Component {
    constructor(props) {
        super(props);
        
        this.changeSelectedColor = this.changeSelectedColor.bind(this);
        this.changeHue = this.changeHue.bind(this);
        this.changeSaturation = this.changeSaturation.bind(this);
        this.changeBrightness = this.changeBrightness.bind(this);
        
        this.updateColor = this.updateColor.bind(this);
        this.hexToHSL = this.hexToHSL.bind(this);
        
        this.state = {
            hue: 120,
            saturation: 100,
            brightness: 50,
            selectedColor: 'hsl(120deg, 100%, 50%)'
        };
    }
    
    changeSelectedColor(hue, saturation, brightness) {
        const newColor = `hsl(${hue}deg, ${saturation}%, ${brightness}%)`;
        this.setState({
            hue: hue,
            saturation: saturation,
            brightness: brightness,
            selectedColor: newColor
        });
    }
    
    changeHue(event) {
        const hue = Number(event.target.value);
        this.changeSelectedColor(hue, this.state.saturation, this.state.brightness);
    }
    
    changeSaturation(event) {
        const saturation = Number(event.target.value);
        this.changeSelectedColor(this.state.hue, saturation, this.state.brightness);
    }
    
    changeBrightness(event) {
        const brightness = Number(event.target.value);
        this.changeSelectedColor(this.state.hue, this.state.saturation, brightness);
    }
    
    hexToHSL(hexValue) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexValue),
            red = parseInt(result[1], 16),
            green = parseInt(result[2], 16),
            blue = parseInt(result[3], 16);
            
        red /= 255;
        green /= 255;
        blue /= 255;
        
        let max = Math.max(red, green, blue), 
            min = Math.min(red, green, blue);
        
        let hue, saturation, lightness = (max + min) / 2;
        
        if(max === min){
            hue = saturation = 0; // achromatic
        } else {
            var difference = max - min;
            saturation = lightness > 0.5 ? difference / (2 - max - min) : difference / (max + min);

            switch(max){
                case red: hue = (green - blue) / difference + (green < blue ? 6 : 0); break;
                case green: hue = (blue - red) / difference + 2; break;
                case blue: hue = (red - green) / difference + 4; break;
                default: return null;
            }

            hue /= 6;
        }
        
        return {
            h: hue,
            s: saturation,
            l: lightness
        };
    }
    
    HSLToHex(hue, saturation, lightness) {
        hue /= 360;
        saturation /= 100;
        lightness /= 100;
        
        let red, green, blue;
        
        if (saturation === 0) {
            red = green = blue = lightness; // achromatic
        } else {
            const hue2rgb = (p, q, t) => {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            };
            const q = lightness < 0.5 ? lightness * (1 + saturation) : lightness + saturation - lightness * saturation;
            const p = 2 * lightness - q;
            red = hue2rgb(p, q, hue + 1 / 3);
            green = hue2rgb(p, q, hue);
            blue = hue2rgb(p, q, hue - 1 / 3);
        }
        const toHex = x => {
            const hex = Math.round(x * 255).toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        };
        
        return `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
    }
    
    updateColor(event) {
        let hexValue = event.target.value,
            hsl_value;
        
        if (hexValue.length !== 7) {
            return;
        }
        
        if (/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.test(hexValue)) {
            hsl_value = this.hexToHSL(hexValue);
            
            if (hsl_value !== null) {
                this.changeSelectedColor(Math.round(hsl_value.h * 360), Math.round(hsl_value.s * 100), Math.round(hsl_value.l * 100));
            }
        }
    }
    
    render() {
        const selectedColorStyles = {
            backgroundColor: this.state.selectedColor
        }, saturationStyles = {
            backgroundImage: `linear-gradient(to right, hsl(${this.state.hue}, 0%, 50%) 0%, hsl(${this.state.hue}, 100%, 50%) 100%)`
        }, brightnessStyles = {
            backgroundImage: `linear-gradient(to right, hsl(${this.state.hue}, 100%, 0%) 0%, hsl(${this.state.hue}, 100%, 50%) 50%, hsl(${this.state.hue}, 100%, 100%) 100%)`
        }, convertedColor = this.HSLToHex(this.state.hue, this.state.saturation, this.state.brightness);
        
        return (
            <section className={styles.ColorComponentHolder}>
                <div className={styles.ProgressBars}>
                    <section className={styles.HueSelector}>
                        <input type="range" min="0" max="360" defaultValue={this.state.hue} onChange={this.changeHue} />
                    </section>

                    <section className={styles.BrightnessSelector} style={brightnessStyles}>
                        <input type="range" min="0" max="100" defaultValue={this.state.brightness} onChange={this.changeBrightness} />
                    </section>

                    <section className={styles.SaturationSelector} style={saturationStyles}>
                        <input type="range" min="0" max="100" defaultValue={this.state.saturation} onChange={this.changeSaturation} />
                    </section>
                </div>
                <div className={styles.SelectedColor} style={selectedColorStyles}>{convertedColor}</div>
            </section> 
        );
    }
}