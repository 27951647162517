import React from 'react';

import UserModify from './UserModify';
import UsersTable from './UsersTable';

export default class Users extends React.Component {
    
    constructor(props) {
        super(props);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        
        this.state = {
            selectedUser: null
        }
    }
    
    handleSubmitForm(user_data) {
        
    }
    
    render() {
        return (
            <div>
                <UserModify user={this.state.selectedUser} submit={this.handleSubmitForm} />
                <UsersTable usersList={this.props.usersList} />
            </div>
        );
    }
}