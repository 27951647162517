import React from 'react';
import styles from './Structure.module.scss';
import {default as tabStyles} from '../tabs/Tabs.module.scss';
import { NavLink, Switch, Route, Redirect } from "react-router-dom";

import Tabs from '../tabs/Tabs';
import Levels from './Levels';
import Locations from './Locations';
import Users from './Users';
import Permissions from './Permissions';
import Translations from '../translations/Translations';
import { levelsHierarchy, locationsHierarchy, usersList, permissionModules } from '../initialData';

export default function Structure() {
    return (
        <section className={styles.FocusSpace}>
            <Tabs>
                <NavLink to="/structure/levels" className={tabStyles.Tab} activeClassName={tabStyles.active}> Levels </NavLink>
                <NavLink to="/structure/locations" className={tabStyles.Tab} activeClassName={tabStyles.active}> Locations </NavLink>
                <NavLink to="/structure/users" className={tabStyles.Tab} activeClassName={tabStyles.active}> Users </NavLink>
                <NavLink to="/structure/permissions" className={tabStyles.Tab} activeClassName={tabStyles.active}> Permissions </NavLink>
            </Tabs>
            
            <Switch>
                <Route path="/structure/levels" component={() => <Levels levelsData={levelsHierarchy} />} />
                <Route path="/structure/locations" component={() => <Locations levelsData={levelsHierarchy} locationsData={locationsHierarchy} />} />
                <Route path="/structure/users" component={() => <Users usersList={usersList} />} />
                <Route path="/structure/permissions" component={() => <Permissions permissions={permissionModules} />} />
                <Route render={() => <Redirect to="/structure/levels" />}/>
            </Switch>
            <Translations />
        </section>
    );
}