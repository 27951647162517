import React from 'react';
import styles from './PageHeader.module.scss';
import { Link } from "react-router-dom";

import logoutIcon from './logout.svg';
import notificationIcon from './notification.svg';
import searchIcon from './search.svg';

export default function PageHeader() {
    return (
        <section className={styles.PageHeader}>
            <div className={styles.SearchBar}>
                <img src={searchIcon} alt="search icon" />
                <input type="text" placeholder="Search..." />
            </div>
            <div className={styles.ProfileUtilitiesHolder}>
                <Link to="/profile" className={styles.name}> Jay Krishna </Link>
                <Link to="/profile" className={styles.nameRepresentation}> <span> JK </span> </Link>
                <picture className={styles.icon + ' ' + styles.bell + ' ' + styles.active}>
                    <img src={notificationIcon} alt="notification icon" />
                </picture>
                <picture className={styles.icon + ' ' + styles.logout}>
                    <img src={logoutIcon} alt="logout icon" />
                </picture>
            </div>
        </section>
    );
}