/*jslint esversion: 6 */

const levelsHierarchy = {
    id: 'top-levels',
    title: 'Top Levels',
    children: [{
        id: 1,
        name: 'Project India',
        children: [{
            id: 1,
            name: 'Country',
            children: [{
                id: 1,
                name: 'Director'
            }, {
                id: 2,
                name: 'National Donor'
            }]
        }, {
            id: 2,
            name: 'State',
            children: [{
                id: 3,
                name: 'State Manager'
            }, {
                id: 4,
                name: 'State Donor'
            }]
        }, {
            id: 3,
            name: 'District',
            children: [{
                id: 5,
                name: 'District Manager'
            }, {
                id: 6,
                name: 'District Donor'
            }]
        }, {
            id: 4,
            name: 'City',
            children: [{
                id: 7,
                name: 'City Manager'
            }, {
                id: 8,
                name: 'City Donor'
            }]
        }, {
            id: 5,
            name: 'Site',
            children: [{
                id: 9,
                name: 'Site Manager'
            }]
        }]
    }, {
        id: 2,
        name: 'Project Nigeria',
        children: [{
            id: 6,
            name: 'Country',
            children: [{
                id: 10,
                name: 'Director'
            }, {
                id: 11,
                name: 'National Donor'
            }]
        }, {
            id: 7,
            name: 'Province',
            children: [{
                id: 12,
                name: 'Province Manager'
            }, {
                id: 13,
                name: 'Province Donor'
            }]
        }, {
            id: 8,
            name: 'Site',
            children: [{
                id: 14,
                name: 'Site Manager'
            }]
        }]
    }]
};

const permissionModules = [{
    id: 1,
    name: 'Members'
}, {
    id: 2,
    name: 'Todos'
}, {
    id: 3,
    name: 'Workflows'
}, {
    id: 4,
    name: 'Questionnaire'
}];

const locationsHierarchy = {
    // ID for Project India
    1: [{
        id: 1,
        name: 'Karnataka',
        children: [{
            id: 2,
            name: 'Kodagu',
            children: [{
                id: 3,
                name: 'Madikeri',
                children: [{
                    id: 4,
                    name: 'Abbey Falls'
                }, {
                    id: 5,
                    name: 'Kote Betta'
                }]
            }, {
                id: 6,
                name: 'Talakaveri',
                children: [{
                    id: 7,
                    name: 'Kaveri River'
                }, {
                    id: 8,
                    name: 'Ranipuram'
                }]
            }]
        }, {
            id: 9,
            name: 'Chikmaglur',
            children: [{
                id: 10,
                name: 'Sringeri',
                children: [{
                    id: 11,
                    name: 'Sharadamba Matha'
                }, {
                    id: 12,
                    name: 'Vidyashankara Temple'
                }]
            }, {
                id: 13,
                name: 'Kemmangundi',
                children: [{
                    id: 14,
                    name: 'Hebbe Falls'
                }, {
                    id: 15,
                    name: 'Shanti Falls'
                }]
            }]
        }]
    }],
    
    // ID for Project Nigeria
    2: [{
        id: 16,
        name: 'Borno',
        children: [{
            id: 17,
            name: 'Lake Chad'
        }, {
            id: 18,
            name: 'Kirawa'
        }]
    }, {
        id: 19,
        name: 'Ilorin',
        children: [{
            id: 20,
            name: 'Pategi Beach'
        }, {
            id: 21,
            name: 'Owu Waterfalls'
        }]
    }]
};

const usersList = [{
    id: 1,
    username: '+91 9999999999',
    level: 'Root',
    entity: 'India',
    role: 'Director',
    manager: true
}, {
    id: 2,
    username: '+91 9999999999',
    level: 'Root',
    entity: 'India',
    role: 'Director',
    manager: true
}, {
    id: 3,
    username: '+91 9999999999',
    level: 'Root',
    entity: 'India',
    role: 'Director',
    manager: true
}, {
    id: 4,
    username: '+91 9999999999',
    level: 'Root',
    entity: 'India',
    role: 'Director',
    manager: true
}, {
    id: 5,
    username: '+91 9999999999',
    level: 'Root',
    entity: 'India',
    role: 'Director',
    manager: true
}, {
    id: 6,
    username: '+91 9999999999',
    level: 'Root',
    entity: 'India',
    role: 'Director',
    manager: true
}, {
    id: 7,
    username: '+91 9999999999',
    level: 'Root',
    entity: 'India',
    role: 'Director',
    manager: true
}];

const workflowsList = [{
    id: 1,
    name: 'Report Incident',
    statuses: [{
        id: 1,
        name: 'Opened',
        due_in_days: 2
    }, {
        id: 2,
        name: 'Need Help',
        due_in_days: 2
    }, {
        id: 3,
        name: 'Closed',
        due_in_days: null
    }],
    categories: [{
        id: 1,
        name: 'Medical'
    }, {
        id: 2,
        name: 'Physical'
    }, {
        id: 3,
        name: 'Legal'
    }],
    priorities: [{
        id: 1,
        name: 'Critical'
    }, {
        id: 2,
        name: 'Severe'
    }, {
        id: 3,
        name: 'Medium'
    }, {
        id: 4,
        name: 'Low'
    }]
}, {
    id: 2,
    name: 'Apply for Aadhar',
    statuses: [{
        id: 1,
        name: 'Opened',
        due_in_days: 2
    }, {
        id: 2,
        name: 'Waiting for feedback',
        due_in_days: 2
    }, {
        id: 3,
        name: 'Closed',
        due_in_days: null
    }],
    categories: [{
        id: 2,
        name: 'Physical'
    }, {
        id: 3,
        name: 'Legal'
    }],
    priorities: [{
        id: 1,
        name: 'Critical'
    }, {
        id: 2,
        name: 'Severe'
    }, {
        id: 3,
        name: 'Medium'
    }, {
        id: 4,
        name: 'Low'
    }]
}];

const todosList = [{
    id: 1,
    name: 'Call hospital',
    statuses: [{
        id: 1,
        name: 'Initiated',
        terminal: false
    }, {
        id: 2,
        name: 'Pending',
        terminal: false
    }, {
        id: 3,
        name: 'Completed',
        terminal: true
    }]
}, {
    id: 2,
    name: 'Confirm phone',
    statuses: [{
        id: 1,
        name: 'Tried once',
        terminal: false
    }, {
        id: 2,
        name: 'Failed',
        terminal: true
    }, {
        id: 3,
        name: 'Successful',
        terminal: true
    }]
}];

const languages = [{
    id: 1,
    name: 'English'
}, {
    id: 2,
    name: 'ಕನ್ನಡ'
}, {
    id: 3,
    name: 'தமிழ்'
}, {
    id: 4,
    name: 'हिंदी'
}, {
    id: 5,
    name: 'తెలుగు'
}];

const labels = [{
    id: 1,
    name: 'Main',
    location: 'LHS Menu'
}, {
    id: 2,
    name: 'Profile',
    location: 'LHS Menu'
}, {
    id: 3,
    name: 'Branding',
    location: 'LHS Menu'
}, {
    id: 4,
    name: 'Language',
    location: 'LHS Menu'
}, {
    id: 5,
    name: 'Hierarchy',
    location: 'LHS Menu'
}];

const questionsList = [{
    id: 1,
    question: 'The first question in the list?',
    type: 'Single Select',
    options: [{
        id: 1,
        name: 'Option One',
        todo: 1,
        goto: 2
    }, {
        id: 2,
        name: 'Option Two',
        todo: null,
        goto: 3
    }]
}, {
    id: 2,
    question: 'The second question in the list?',
    type: 'Text'
}];

export { levelsHierarchy, locationsHierarchy, usersList, todosList, workflowsList, languages, labels, permissionModules, questionsList };