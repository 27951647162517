import React from 'react';
import styles from './UserModify.module.scss';

import InputText from '../form/InputText';
import Button from '../form/Button';
import chevronIcon from '../form/chevron-arrow-up.svg';
import searchIcon from '../form/search.svg';
import mapIcon from '../form/map-pin.svg';

export default class UserModify extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.toggleSectionCollapse = this.toggleSectionCollapse.bind(this);
        
        this.changeRole = this.changeRole.bind(this);
        this.changeLocation = this.changeLocation.bind(this);
        
        this.state = {
            collapsed: true,
            userData: {
                
            }
        };
    }
    
    toggleSectionCollapse() {
        let toggledCollapse = !this.state.collapsed;
        
        this.setState({
            collapsed: toggledCollapse
        });
    }
    
    changeRole(role) {
        let updatedUserData = {
            ...this.state.userData,
            role: role
        };
        
        this.setState({
            userData: updatedUserData
        });
    }
    
    changeLocation(location) {
        let updatedUserData = {
            ...this.state.userData,
            location: location
        };
        
        this.setState({
            userData: updatedUserData
        });
    }
    
    render() {
        return (
            <section className={this.state.collapsed ? styles.collapsedModifyUser : styles.modifyUser}>
                <header onClick={this.toggleSectionCollapse}>
                    <h2 className={styles.formHeading}>Add / Edit User</h2>
                    <img src={chevronIcon} alt="Toggle modification" />
                </header>
                <div className={styles.allInputsHolder}>
                    <div className={styles.inputSegment}>
                        <InputText placeholder="Select Role" icon={chevronIcon} options={['Role 1', 'Role 2']} onChange={this.changeRole} />
                    </div>
                    <div className={styles.inputSegment}>
                        <InputText placeholder="Location" icon={searchIcon} options={['Location 1', 'Location 2']} onChange={this.changeLocation} />
                    </div>
                    <div className={styles.phoneSegment}>
                        <InputText default="+91" icon={chevronIcon} options={['+91', '+1']} disableSearch />
                        <InputText placeholder="Phone" />
                    </div>
                    
                    <div className={styles.geoLocationSegment}>
                        <InputText placeholder="Latitude" />
                        <InputText placeholder="Longitude" />
                        <img src={mapIcon} alt="Location selector"/>
                    </div>
                    <div className={styles.inputSegment}>
                        <InputText placeholder="Language" icon={chevronIcon} options={['Language 1', 'Language 2']} />
                    </div>
                    <div className={styles.inputSegment}>
                        <InputText placeholder="App User?" icon={chevronIcon} options={['Yes', 'No']} disableSearch />
                    </div>
                    
                    <div className={styles.inputSegment}>
                        <InputText placeholder="Email" />
                    </div>
                    
                    <div className={styles.inputSegment}>
                        <InputText placeholder="Password" />
                    </div>
                    
                </div>
                    
                <div className={styles.buttonHolder}>
                    <Button text="Add" onClick={this.props.submit} />
                </div>
                
            </section>
        );
    }
}