import React from 'react';
import styles from './Locations.module.scss';
import DraggableCardsList from '../cards-list/DraggableCardsList';
import CardForm from '../card/CardForm';

import InputText from '../form/InputText';

export default class Levels extends React.Component {
    constructor(props) {
        super(props);
        
        this.handleTopLevelSelection = this.handleTopLevelSelection.bind(this);
        
        this.handleTopLevelReorder = this.handleTopLevelReorder.bind(this);
        
        this.state = {
            selectedTopLevel: null,
            levelsData: this.props.levelsData,
            locationsData: this.props.locationsData,
            locationVerticals: null
        };
    }
    
    reOrder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }
    
    handleTopLevelSelection(card_id) {
        let topLevelCard, newState;
        
        for (let i = 0; i < this.state.levelsData.children.length; i += 1) {
            let topLevel = this.state.levelsData.children[i];
            
            if (topLevel.id === card_id) {
                topLevelCard = topLevel;
                break;
            }
        }
        
        newState = {
            selectedTopLevel: topLevelCard,
            locationVerticals: new Array(topLevelCard.children.length - 1)
        };
        
        for (let i = 0; i < newState.locationVerticals.length; i += 1) {
            newState.locationVerticals[i] = {
                selected: null,
                newLocationName: '',
                showAddForm: false,
                locations: []
            };
        }
        
        newState.locationVerticals[0].locations = this.state.locationsData[topLevelCard.id].slice(0);
        this.setState(newState);
    }
    
    handleTopLevelReorder(source_index, destination_index) {
        let newState = JSON.parse(JSON.stringify(this.state));
        
        newState.levelsData.children = this.reOrder(newState.levelsData.children, source_index, destination_index);
        
        this.setState(newState);
    }
    
    handleLocationSelection(vertical_index, card_id) {
        let newState = JSON.parse(JSON.stringify(this.state));
        
        for (let i = 0; i < newState.locationVerticals[vertical_index].locations.length; i += 1) {
            let locationCard = newState.locationVerticals[vertical_index].locations[i];
            
            if (locationCard.id === card_id) {
                newState.locationVerticals[vertical_index].selected = JSON.parse(JSON.stringify(locationCard));
                newState.locationVerticals[vertical_index].selected.locations = [];
                break;
            }
        }
        
        
        if (newState.locationVerticals.length - 1 !== vertical_index) {
            for (let i = vertical_index + 1; i < newState.locationVerticals.length; i += 1) {
                newState.locationVerticals[i] = {
                    selected: null,
                    newLocationName: '',
                    showAddForm: false,
                    locations: []
                };
            }
            
            newState.locationVerticals[vertical_index + 1] = {
                selected: null,
                newLocationName: '',
                showAddForm: false,
                locations: newState.locationVerticals[vertical_index].selected.children.splice(0)
            };
        }
        
        this.setState(newState);
    }
    
    handleLocationReorder(vertical_index, source_index, destination_index) {
        let newState = JSON.parse(JSON.stringify(this.state));
        
        newState.locationVerticals[vertical_index].locations = this.reOrder(newState.locationVerticals[vertical_index].locations, source_index, destination_index);
        
        this.setState(newState);
    }
    
    toggleAddForm(vertical_index) {
        let newState = Object.assign({}, this.state);
        newState.locationVerticals[vertical_index].showAddForm = !newState.locationVerticals[vertical_index].showAddForm;
        this.setState(newState);
    }
    
    updateLocationName(vertical_index, value) {
        let newState = Object.assign({}, this.state);
        newState.locationVerticals[vertical_index].newLocationName = value;
        
        
        this.setState(newState);
    }
    
    addLocation(vertical_index) {
        let newState = Object.assign({}, this.state);
        
        let new_id = 1;

        if (newState.levelsData.children.length > 0) {
            new_id = newState.locationVerticals[vertical_index].locations[newState.locationVerticals[vertical_index].locations.length - 1].id + 1;
        }
        
        newState.locationVerticals[vertical_index].locations.push({
            id: new_id,
            name: newState.locationVerticals[vertical_index].newLocationName,
            children: []
        });
        
        newState.locationVerticals[vertical_index].newLocationName = '';
        newState.locationVerticals[vertical_index].showAddForm = false;
        
        this.setState(newState);
    }
    
    render() {
        const locationHierarchy = this.state.selectedTopLevel ? this.state.selectedTopLevel.children.slice(1) : [];
        let addLocationForms = [];
        
        for (let i = 0; i < locationHierarchy.length; i += 1) {
            let locationCard = <CardForm submitForm={this.addLocation.bind(this, i)}>
                <InputText placeholder="Name" onChange={this.updateLocationName.bind(this, i)} />
            </CardForm>;
            
            addLocationForms.push(locationCard)
        }
        
        const locationCardsLists = locationHierarchy.map((locationLevel, index) => {
            let isPreviousColumnSelected = false;
            
            if (index === 0) {
                isPreviousColumnSelected = !!this.state.selectedTopLevel;
            } else {
                isPreviousColumnSelected = !!this.state.locationVerticals[index - 1].selected;
            }
            
            return (
                <DraggableCardsList 
                    key={index}
                    heading={locationLevel.name}
                    cards={this.state.locationVerticals[index].locations}
                    selected={this.state.locationVerticals[index].selected}
                    onSelectCard={this.handleLocationSelection.bind(this, index)}
                    onReorderCards={this.handleLocationReorder.bind(this, index)}
                    addForm={isPreviousColumnSelected && addLocationForms[index]}
                    showAddForm={this.state.locationVerticals[index].showAddForm}
                    onAddToggle={this.toggleAddForm.bind(this, index)}
                />
            );
        });
        
        return (
            <div className={styles.cardsTree}>
                <DraggableCardsList 
                    heading="Top Levels"
                    cards={this.state.levelsData.children}
                    selected={this.state.selectedTopLevel} 
                    onSelectCard={this.handleTopLevelSelection} 
                    onReorderCards={this.handleTopLevelReorder}
                />
                {locationCardsLists}
            </div>
        );
    }
}