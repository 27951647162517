import React from 'react';
import styles from './Todos.module.scss';
import {default as tabStyles} from '../tabs/Tabs.module.scss';
import { NavLink, Switch, Route, Redirect } from "react-router-dom";

import Tabs from '../tabs/Tabs';
import Translations from '../translations/Translations';
import Configuration from './Configuration';
import List from './List';

export default function Todos(props) {
    
    return (
        <section className={styles.FocusSpace}>
            <Tabs>
                <NavLink to="/todos/configuration" className={tabStyles.Tab} activeClassName={tabStyles.active}> Todo Configuration </NavLink>
                <NavLink to="/todos/list" className={tabStyles.Tab} activeClassName={tabStyles.active}> All Todos </NavLink>
            </Tabs>
            <Switch>
                <Route path="/todos/configuration" component={() => <Configuration />} />
                <Route path="/todos/list" component={() => <List />} />
                <Route render={() => <Redirect to="/todos/configuration" />}/>
            </Switch>
            <Translations />
        </section>
    );
}