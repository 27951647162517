import React from 'react';
import styles from './Question.module.scss';

import OptionView from './OptionView';

function selectQuestion(callback, question, index) {
    callback(question, index);
}

export default function Question(props) {
    let options = null;
    
    if (props.question.options) {
        options = props.question.options.map((option, index) => {
            return <OptionView key={option.id} option={option} index={index} />
        });
    }
    
    return (
        <section className={props.selected ? styles.selectedQuestionHolder : styles.questionHolder} onClick={() => selectQuestion(props.onSelect, props.question, props.index)}>
            <section className={styles.questionIndex}>Question {props.index + 1}</section>
            <section className={styles.question}>{props.question.question}</section>
            {options && <div className={styles.separator}></div>}
            {options && <div className={styles.optionsHolder}>
                {options}
            </div>}
        </section>
    );
}