import React from 'react';
import styles from './Option.module.scss';


export default function Option(props) {
    const startIndex = props.value.toLocaleLowerCase().indexOf(props.search.toLocaleLowerCase());
    const endIndex = startIndex + props.search.length;
    let optionMarkup;
    
    if (props.search === '' || startIndex === endIndex) {
        optionMarkup = <span>{props.name}</span>;
    } else {
        optionMarkup = <span>{props.name.substring(0, startIndex)}<em>{props.name.substring(startIndex, endIndex)}</em>{props.name.substring(endIndex)}</span>
    }
    
    return <section className={styles.option} onClick={() => props.onClick(props.value)}>{optionMarkup}</section>
}