import React from 'react';
import styles from './Todos.module.scss';
import DraggableCardsList from '../cards-list/DraggableCardsList';
import CardForm from '../card/CardForm';

import InputText from '../form/InputText';
import { todosList } from '../initialData'

export default class Levels extends React.Component {
    constructor(props) {
        super(props);
        
        this.handleTodoSelection = this.handleTodoSelection.bind(this);
        this.handleStatusSelection = this.handleStatusSelection.bind(this);
        
        this.handleTodoReorder = this.handleTodoReorder.bind(this);
        this.handleStatusReorder = this.handleStatusReorder.bind(this);
        
        this.addTodo = this.addTodo.bind(this);
        this.addStatus = this.addStatus.bind(this);
        
        this.updateTodoName = this.updateTodoName.bind(this);
        this.updateStatusName = this.updateStatusName.bind(this);
        this.updateStatusIsTerminal = this.updateStatusIsTerminal.bind(this);
        
        this.toggleTodoAddForm = this.toggleTodoAddForm.bind(this);
        this.toggleStatusAddForm = this.toggleStatusAddForm.bind(this);
        
        this.state = {
            selectedTodo: null,
            selectedStatus: null,
            
            newTodoName: '',
            newStatusName: '',
            newStatusIsTerminal: '',
            
            showAddTodoForm: false,
            showAddStatusForm: false,
            
            todos: Object.assign([], todosList)
        };
    }
    
    reOrder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }
    
    handleTodoSelection(card_id) {
        let todoCard;
        
        for (let i = 0; i < this.state.todos.length; i += 1) {
            let todo = this.state.todos[i];
            
            if (todo.id === card_id) {
                todoCard = todo;
                break;
            }
        }
        
        this.setState({
            selectedTodo: todoCard,
            selectedStatus: null
        });
    }
    
    handleTodoReorder(source_index, destination_index) {
        let newState = JSON.parse(JSON.stringify(this.state));
        
        newState.todos = this.reOrder(newState.todos, source_index, destination_index);
        
        this.setState(newState);
    }
    
    toggleTodoAddForm() {
        let toggledState = !this.state.showAddTodoForm;
        
        this.setState({
            showAddTodoForm: toggledState
        });
    }
    
    updateTodoName(value) {
        this.setState({
            newTodoName: value
        });
    }
    
    addTodo() {
        let newState = Object.assign({}, this.state);
        
        let new_id = 1;

        if (newState.todos.length > 0) {
            new_id = newState.todos[newState.todos.length - 1].id + 1;
        }
        
        newState.todos.push({
            id: new_id,
            name: newState.newTodoName,
            statuses: []
        });
        
        newState.newTodoName = '';
        newState.showAddTodoForm = false;
        
        this.setState(newState);
    }
    
    handleStatusSelection(card_id) {
        let statusCard;
        
        for (let i = 0; i < this.state.selectedTodo.statuses.length; i += 1) {
            let status = this.state.selectedTodo.statuses[i];
            
            if (status.id === card_id) {
                statusCard = status;
                break;
            }
        }
        
        this.setState({
            selectedStatus: statusCard
        });
    }
    
    handleStatusReorder(source_index, destination_index) {
        let newState = JSON.parse(JSON.stringify(this.state));
        
        for (let i = 0; i < newState.todos.length; i += 1) {
            if (newState.todos[i].id === newState.selectedTodo.id) {
                newState.todos[i].children = this.reOrder(newState.todos[i].children, source_index, destination_index);
                newState.selectedTodo = newState.todos[i];
            }
        }
        
        this.setState(newState);
    }
    
    toggleStatusAddForm() {
        let toggledState = !this.state.showAddStatusForm;
        
        this.setState({
            showAddStatusForm: toggledState
        });
    }
    
    updateStatusName(value) {
        this.setState({
            newStatusName: value
        });
    }
    
    updateStatusIsTerminal(value) {
        this.setState({
            newStatusIsTerminal: value
        });
    }
    
    addStatus() {
        let newState = Object.assign({}, this.state);
        
        if (!newState.selectedTodo) {
            return;
        }
        
        for (let i = 0; i < newState.todos.length; i += 1) {
            if (newState.todos[i].id === newState.selectedTodo.id) {
                let new_id = 1;

                if (newState.selectedTodo.statuses.length > 0) {
                    new_id = newState.selectedTopLevel.statuses[newState.selectedTopLevel.statuses.length - 1].id + 1;
                }
                
                newState.todos[i].statuses.push({
                    id: new_id,
                    name: newState.newStatusName,
                    terminal: newState.newStatusIsTerminal === 'Yes'
                });
            }
        }
        
        newState.newStatusName = '';
        newState.showAddStatusForm = false;
        
        this.setState(newState);
    }
    
    render() {
        
        const statuses = (this.state.selectedTodo ? this.state.selectedTodo.statuses : []).map(status => {
            status.details = status.terminal ? 'Terminal' : 'Instrumental';
            return status;
        });
        
        const addTodoForm = <CardForm submitForm={this.addTodo}>
            <InputText placeholder="Name" onChange={this.updateTodoName} />
        </CardForm>;
        
        const addStatusForm = <CardForm submitForm={this.addStatus}>
            <InputText placeholder="Name" onChange={this.updateStatusName} />
            <InputText placeholder="Is Terminal?" onChange={this.updateStatusIsTerminal} options={['Yes', 'No']} disableSearch />
        </CardForm>;
        
        return (
            <div className={styles.cardsTree}>
                <DraggableCardsList 
                    heading="Todos"
                    cards={this.state.todos}
                    selected={this.state.selectedTodo} 
                    onSelectCard={this.handleTodoSelection} 
                    onReorderCards={this.handleTodoReorder}
                    addForm={addTodoForm}
                    showAddForm={this.state.showAddTodoForm}
                    onAddToggle={this.toggleTodoAddForm}
                />

                <DraggableCardsList 
                    heading="Statuses" 
                    cards={statuses} 
                    selected={this.state.selectedStatus} 
                    onSelectCard={this.handleStatusSelection}  
                    onReorderCards={this.handleStatusReorder}
                    addForm={!!this.state.selectedTodo && addStatusForm}
                    showAddForm={this.state.showAddStatusForm}
                    onAddToggle={this.toggleStatusAddForm}
                />
            </div>
        );
    }
}