import React from 'react';
import styles from './Card.module.scss';

import deleteIcon from './trash.svg';

export default function Card(props) {
    
    return (
        <li className={props.active ? styles.active : styles.normal} 
            onClick={() => props.onSelectCard()}>
            <div className={styles.index}> {props.index + 1} </div>
            <div className={styles.details}> 
                <div className={styles.title}>{props.card.name}</div> 
                {props.card.details && <div className={styles.subTitle} title={props.card.details}>{props.card.details}</div> }
            </div>
            <div className={styles.delete}>
                <img src={deleteIcon} alt="Delete card"/>
            </div>
        </li>
    );
}