import React from 'react';
import styles from './Toggle.module.scss';


export default class Toggle extends React.Component  {
    constructor(props) {
        super(props);
        this.toggleButton = this.toggleButton.bind(this);
    }
    
    toggleButton() {
        this.props.onToggle(this.props.toggleId);
    }
    
    render() {
        return (
            <section className={this.props.on ? styles.on : styles.off} onClick={this.toggleButton}>
                <div className={styles.slider}></div>
            </section>
        );
    }
}